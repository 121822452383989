const domainToEnv = {
  localhost: 'local',
  'test-timeclock.loadedhub.com': 'test',
  'qa-timeclock.loadedhub.com': 'qa',
  'staging-timeclock.loadedhub.com': 'staging',
  'timeclock.loadedhub.com': 'production',
}

const envConfig = {
  local: {
    mercuryApiUrl: 'https://localhost:7019',
  },
  test: {
    mercuryApiUrl: 'https://test-timeclock.loadedhub.com/api',
  },
  qa: {
    mercuryApiUrl: 'https://qa-timeclock.loadedhub.com/api',
  },
  staging: {
    mercuryApiUrl: 'https://staging-timeclock.loadedhub.com/api',
  },
  production: {
    mercuryApiUrl: 'https://timeclock.loadedhub.com/api',
  },
}

// check the current window.location.hostname and set the env accordingly
export default envConfig[domainToEnv[window.location.hostname] || 'production']
