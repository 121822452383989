import { SharedSlice } from '@/components/slice'
import { IStaffMembersDetailSummaryResponse } from '@/models'
import { staffMemberService } from '@/services'
import { exceptionMessageHandler } from '@/utils'
import { StateCreator } from 'zustand'

interface StaffMembersState {
  staffMembers: IStaffMembersDetailSummaryResponse[]
  searchValue: string
}
export interface StaffMembersSlice extends StaffMembersState {
  /**
   * Get Staff Members list
   * @param includeDeleted
   */
  getStaffMembers: (includeDeleted?: boolean) => Promise<void>

  /**
   * Set search value in Staff Members list
   * @param searchValue
   * @returns
   */
  setSearchValue: (searchValue: string) => void

  /**
   * Resets StaffMembers Slice state
   */
  resetStaffMembersSlice: () => void
}

const initialState: StaffMembersState = {
  staffMembers: [],
  searchValue: '',
}

const createStaffMembersSlice: StateCreator<
  StaffMembersSlice & SharedSlice,
  [],
  [],
  StaffMembersSlice
> = (set) => ({
  // State variables
  ...initialState,

  // State setters
  resetStaffMembersSlice: () => set(initialState),

  setSearchValue: (searchValue: string) => set({ searchValue }),

  getStaffMembers: (includeDeleted: boolean = true): Promise<void> => {
    //It defaults to today's date in order to get all the staff members until today
    return staffMemberService
      .getAll(undefined, includeDeleted)
      .then((result) => {
        set(() => ({ staffMembers: result }))
      })
      .catch((error) => {
        set(() => ({
          staffMembers: [],
        }))
        exceptionMessageHandler(error, 'Failed to retrieve staff members')
      })
      .finally(() => {
        set(() => ({ isLoading: false }))
      })
  },
})

export default createStaffMembersSlice
