import { logger } from '@/utils'
import { defer } from 'react-router-dom'
import useWorkforceStore from './store'

export default async function loader() {
  logger.debug('[loader] Staff Members loader')

  const workforceState = useWorkforceStore.getState()
  const getStaffMembers =
    workforceState.staffMembers.length === 0 &&
    useWorkforceStore.getState().getStaffMembers(false)

  return defer({
    data: Promise.all([getStaffMembers])
      .then(() => true)
      .catch((error) => {
        logger.error('Staff Members Promise.all error', error)
        return false
      }),
  })
}
