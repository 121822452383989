import { coreApi } from '@/api'
import { IStaffMembersDetailSummaryResponse } from '@/models'
import { fetchWithValidation, singletonQueryClient } from '@/utils'
import { Dayjs } from 'dayjs'
import orderBy from 'lodash/orderBy'

enum QueryKeys {
  GetStaffMembers = 'staffMembers',
}

/**
 * Get all Staff Members
 * @param includeDeleted - include the ones with datestampDeleted = true
 * @param time - include all the staff member until a specific time
 * @returns Promise<StaffMemberDetail[]>
 */
export const getAll = (
  time?: Dayjs,
  includeDeleted: boolean = true,
): Promise<IStaffMembersDetailSummaryResponse[]> => {
  return singletonQueryClient.fetchQuery({
    queryKey: [QueryKeys.GetStaffMembers, { time, includeDeleted }],
    queryFn: async () => {
      const staffMembers = await fetchWithValidation(() =>
        coreApi.getStaffMembers(time?.utc(), includeDeleted),
      )

      return orderBy(
        staffMembers,
        [(option: { name: string }) => option.name.toLowerCase()],
        ['asc'],
      )
    },
  })
}
