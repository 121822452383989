import { FC, Suspense, useEffect, useMemo } from 'react'
import { Action, ErrorBoundary, singletonQueryClient } from './utils'
import dayjs from 'dayjs'
import './index.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { useCompanySettings } from './hooks'
import useAppStore from './state/store'
import getTheme from './theme'
import { RouterProvider } from 'react-router-dom'
import getRouter from './router'
import { ThemeProvider } from '@mui/material'
import { FullPageLoader, LoadedSnackbar } from './components'

const snackbarProviderProps: SnackbarProviderProps = {
  anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
  maxSnack: 3,
  autoHideDuration: 6000,
  action: Action,
  preventDuplicate: true,
  hideIconVariant: true,
  Components: {
    loadedSnackbar: LoadedSnackbar,
  },
}

const App: FC = () => {
  const activeCompany = useAppStore((state) => state.activeCompany)
  const colorMode = useAppStore((state) => state.colorMode)

  const theme = useMemo(() => getTheme(colorMode), [colorMode])
  const routes = useMemo(() => getRouter(activeCompany), [activeCompany])

  const { localePreference, weekStartPreference } = useCompanySettings()

  useEffect(() => {
    dayjs.updateLocale(localePreference.toLocaleLowerCase(), {
      weekStart: weekStartPreference,
    })
  }, [localePreference, weekStartPreference])

  return (
    <QueryClientProvider client={singletonQueryClient}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider {...snackbarProviderProps}>
          {/* This is needed to make the date pickers work with the right locale */}
          {/* <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale={localePreference.toLocaleLowerCase()}
                > */}
          <ErrorBoundary>
            <Suspense fallback={<FullPageLoader />}>
              <RouterProvider router={routes} />
            </Suspense>
          </ErrorBoundary>
          {/* </LocalizationProvider> */}
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
