import { create } from 'zustand'
import { SharedSlice, createSharedSlice } from '../../../slice'
import createStaffMembersSlice, { StaffMembersSlice } from './slice'
import createStaffMemberSlice, { StaffMemberSlice } from './edit/slice'

const useWorkforceStore = create<
  StaffMembersSlice & StaffMemberSlice & SharedSlice
>()((...props) => ({
  ...createSharedSlice(...props),
  ...createStaffMembersSlice(...props),
  ...createStaffMemberSlice(...props),
}))

export default useWorkforceStore
