import { useEffect, useRef } from 'react'

/**
 * Custom hook to perform an API call at specified intervals.
 * @param method - The method to be executed.
 * @param interval - Interval in seconds (default is 3600 seconds or 1 hour).
 */
export const useActionInterval = (
  method: () => void,
  interval: number = 3600,
) => {
  const savedCallback = useRef<() => void>()

  // Remember the latest apiCall function.
  useEffect(() => {
    savedCallback.current = method
  }, [method])

  // Set up the interval.
  useEffect(() => {
    const tick = () => {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    if (interval > 0) {
      const id = setInterval(tick, interval * 1000)
      return () => clearInterval(id)
    }
  }, [interval])
}
