import { SharedSlice } from '@/components/slice'
import {
  IStaffMembersDetailSummaryResponse,
  IStaffMemberRoleSummaryModel,
  IClockinResponse,
} from '@/models'
import { shiftService } from '@/services'
import { enqueueLoadedSnackbar, exceptionMessageHandler, logger } from '@/utils'
import { StateCreator } from 'zustand'

interface StaffMemberState {
  selectedStaffMember: IStaffMembersDetailSummaryResponse | undefined
  selectedRole: IStaffMemberRoleSummaryModel | undefined
  lastShift: IClockinResponse | undefined
  isUpdatingShift: boolean
}

export interface StaffMemberSlice extends StaffMemberState {
  /**
   * Reset StaffMember Slice
   */
  resetStaffMemberSlice: () => void

  setSelectedStaffMember: (
    staffMemberDetail: IStaffMembersDetailSummaryResponse | undefined,
  ) => void

  setSelectedRole: (
    staffMemberRole: IStaffMemberRoleSummaryModel | undefined,
  ) => void

  /**
   * Get last ShiftDetail for a staff member
   * @param staffMemberId
   */
  getLastShiftByStaffMemberId: (
    staffMemberId: string,
  ) => Promise<IClockinResponse | undefined>

  /**
   * Add new ShiftDetail
   * @param shiftDetail ShiftDetail to add
   * @param successMessage Success Message to display if the update was successful
   * @param errorMessage Error Message to display if the update has failed
   */
  addShiftDetail: (
    shiftDetail: IClockinResponse,
    successMessage?: string,
    errorMessage?: string,
  ) => Promise<void>

  /**
   * Update ShiftDetail
   * @param shiftDetail ShiftDetail to update
   * @param successMessage Success Message to display if the update was successful
   * @param errorMessage Error Message to display if the update has failed
   */
  updateShiftDetail: (
    shiftDetail: IClockinResponse,
    successMessage?: string,
    errorMessage?: string,
  ) => Promise<void>
}

const initialState: StaffMemberState = {
  selectedStaffMember: undefined,
  selectedRole: undefined,
  lastShift: undefined,
  isUpdatingShift: false,
}

const createStaffMemberSlice: StateCreator<
  StaffMemberSlice & SharedSlice,
  [],
  [],
  StaffMemberSlice
> = (set, get) => ({
  // State variables
  ...initialState,

  // State setters
  resetStaffMemberSlice: () => set(initialState),

  getLastShiftByStaffMemberId: async (
    staffMemberId: string,
  ): Promise<IClockinResponse | undefined> => {
    set(() => ({ isLoading: true }))
    return shiftService
      .getLastShiftByStaffMemberId(staffMemberId)
      .then((result) => {
        set(() => ({ lastShift: result }))
        return result
      })
      .catch((error) => {
        set(() => ({
          lastShift: undefined,
        }))
        exceptionMessageHandler(error, 'Failed to retrieve last shift')
        return undefined
      })
      .finally(() => {
        set(() => ({ isLoading: false }))
      })
  },

  setSelectedStaffMember: (
    staffMemberDetail: IStaffMembersDetailSummaryResponse | undefined,
  ) => {
    set(() => ({ selectedStaffMember: staffMemberDetail }))
  },

  setSelectedRole: (
    staffMemberRole: IStaffMemberRoleSummaryModel | undefined,
  ) => {
    set(() => ({ selectedRole: staffMemberRole }))
  },

  addShiftDetail: async (
    shiftDetail: IClockinResponse,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    try {
      const createdShiftDetail: IClockinResponse =
        await shiftService.add(shiftDetail)

      if (!createdShiftDetail) {
        logger.error(`${errorMessage} - createdShiftDetail doesn't exist`)
        enqueueLoadedSnackbar(errorMessage, 'error')
        return
      }

      set(() => ({
        lastShift: createdShiftDetail,
      }))

      enqueueLoadedSnackbar(successMessage, 'success')
    } catch (error) {
      exceptionMessageHandler(error, errorMessage)
    }
  },

  updateShiftDetail: async (
    shiftDetail: IClockinResponse,
    successMessage?: string,
    errorMessage?: string,
  ) => {
    try {
      set(() => ({ isUpdatingShift: true }))
      const updatedShiftDetail: IClockinResponse =
        await shiftService.update(shiftDetail)

      if (!updatedShiftDetail) {
        logger.error(`${errorMessage} - updatedShiftDetail doesn't exist`)
        enqueueLoadedSnackbar(errorMessage, 'error')
        return
      }

      // We do this check in case a user has clicked on the Clock in/out or Start/End break buttons
      // and has immediately changed staff member or role - if the lastShift is different from the updatedShiftDetail
      // we don't want to update the lastShift as it means it has changed the staff member or role
      if (get().lastShift?.id === updatedShiftDetail.id) {
        set(() => ({
          lastShift: updatedShiftDetail,
        }))
      }

      enqueueLoadedSnackbar(successMessage, 'success')
    } catch (error) {
      exceptionMessageHandler(error, errorMessage)
    } finally {
      set(() => ({ isUpdatingShift: false }))
    }
  },
})

export default createStaffMemberSlice
