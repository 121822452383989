import { FullPageError, TopBar } from '@/components'
import { useActionInterval, useOnlineStatus } from '@/hooks'
import { Box, Stack } from '@mui/material'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import useWorkforceStore from './staff-members/store'

const AppLayout: FC = () => {
  const { isOnline } = useOnlineStatus()

  const getStaffMembers = useWorkforceStore((state) => state.getStaffMembers)
  useActionInterval(() => getStaffMembers(false), 43200) // 43200 seconds = 12 hours

  if (!isOnline) {
    return <FullPageError noInternet />
  }

  return (
    <Stack
      sx={{
        height: '100%',
        backgroundColor: 'background.default',
        overflow: 'hidden',
      }}
    >
      <TopBar />
      <Box
        sx={{
          height: '100%',
        }}
      >
        <Outlet />
      </Box>
    </Stack>
  )
}

export default AppLayout
