import { coreApi } from '@/api'
import { IClockinResponse, ClockinResponse } from '@/models'
import { fetchWithValidation, singletonQueryClient } from '@/utils'

enum QueryKeys {
  GetLastLoadedClockByStaffMemberId = 'getLastLoadedClockByStaffMemberId',
}

/**
 * Get last ShiftDetail for a staff member
 * @param staffMemberId
 * @returns Promise<ShiftDetail | undefined>
 */
export const getLastShiftByStaffMemberId = async (
  staffMemberId: string,
): Promise<IClockinResponse | undefined> => {
  return singletonQueryClient.fetchQuery({
    queryKey: [QueryKeys.GetLastLoadedClockByStaffMemberId, { staffMemberId }],
    queryFn: async () => {
      return await fetchWithValidation(() =>
        coreApi.getLastLoadedClockByStaffMemberId(staffMemberId),
      )
    },
  })
}

/**
 * Add a new ShiftDetail
 * @param shiftDetail
 * @returns Promise<ShiftDetail>
 */
export const add = async (
  shiftDetail: IClockinResponse,
): Promise<IClockinResponse> => {
  const createdShift = await fetchWithValidation(() =>
    coreApi.addClockin(shiftDetail as ClockinResponse),
  )

  return createdShift
}

/**
 * Update a ShiftDetail
 * @param shiftDetail to udpate
 * @returns Promise<ShiftDetail>
 */
export const update = async (
  shiftDetail: IClockinResponse,
): Promise<IClockinResponse> => {
  // Remove the hourlyRate property so the API doesn't get confused about a user
  // without the payroll permission attempting to update the hourlyRate to 'null'.
  // The API is currently designed to have this property missing for users without
  // the payroll permission.
  delete shiftDetail.hourlyRate

  const updatedShift = await fetchWithValidation(() =>
    coreApi.updateClockin(
      shiftDetail.id!,
      // The updateClockin function expects a ClockinRequest object. However, since we currently have
      // both a Request model and a Response model that are identical, we can simply pass the object as-is.
      // In the future, we plan to eliminate these unnecessary duplicate models in the core-api-sdk
      // and also remove the direct exposure of the DTO (Data Transfer Object) objects within the SDK (like
      // the BreakDto class in the ClockinRequest model)
      shiftDetail as ClockinResponse,
    ),
  )

  return updatedShift
}
