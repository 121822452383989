import { lazy } from 'react'
import StaffMembersLoader from './staff-members/loader'
import StaffMembersEditLoader from './staff-members/edit/loader'

export default {
  StaffMembers: {
    Component: lazy(() => import('./staff-members/StaffMembers.Page')),
    loader: StaffMembersLoader,
    Edit: {
      Component: lazy(() => import('./staff-members/edit/StaffMember.Page')),
      loader: StaffMembersEditLoader,
    },
  },
  Settings: {
    Component: lazy(() => import('./settings/Settings.Page')),
  },
}
