import { Navigate, RouteObject } from 'react-router-dom'
import { default as Auth } from './routes/auth'
import { default as App } from './routes/app'
import AppLayout from './routes/app/AppLayout'

export const Routes = [
  {
    path: '/',
    element: <AppLayout />,
    loader: App.StaffMembers.loader,
    children: [
      {
        path: 'staff-members',
        element: <App.StaffMembers.Component />,
        loader: App.StaffMembers.loader,
        children: [
          {
            path: ':id?',
            index: true,
            element: <App.StaffMembers.Edit.Component />,
            loader: App.StaffMembers.Edit.loader,
          },
        ],
      },
      {
        path: 'settings',
        element: <App.Settings.Component />,
      },
      {
        path: '*',
        element: <Navigate to="/staff-members" />,
      },
      {
        path: '/',
        element: <Navigate to="/staff-members" />,
      },
    ],
  },
] as RouteObject[]

export const AuthRoutes = [
  {
    path: 'connect',
    element: <Auth.Connect.Component />,
  },
  {
    path: '*',
    element: <Navigate to="/connect" />,
  },
] as RouteObject[]

export const CommonRoutes = [
  {
    path: 'install',
    element: <Auth.Install.Component />,
  },
]
