import { FC } from 'react'
import { AppBar, IconButton, Stack, Toolbar, Typography } from '@mui/material'
import {
  Tune as TuneIcon,
  KeyboardBackspace as KeyboardBackspaceIcon,
} from '@mui/icons-material'
import { useLocation, useNavigate } from 'react-router-dom'
import useAppStore from '@/state/store'
import { useShallow } from 'zustand/react/shallow'

const TopBar: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const settingsPath = '/settings'

  const [activeCompany] = useAppStore(
    useShallow((state) => [state.activeCompany]),
  )

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'none',
        backgroundColor: 'background.default',
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: 'background.default',
          color: 'text.primary',
          placeContent:
            location.pathname === settingsPath ? 'space-between' : 'flex-end',
        }}
      >
        {location.pathname === settingsPath && (
          <KeyboardBackspaceIcon
            id="settings-page-button"
            sx={{
              color: 'text.primary',
              fontSize: {
                xs: '1.8rem',
                sm: '1.8rem',
                md: '2rem',
                lg: '2.2rem',
                xl: '2.2rem',
              },
            }}
            onClick={() => navigate('/staff-members')}
          />
        )}
        <Stack
          direction={'row'}
          alignItems={'center'}
          paddingTop={2}
          spacing={2}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: {
                xs: '1rem',
                sm: '1rem',
                md: '1.2rem',
                lg: '1.4rem',
                xl: '1.4rem',
              },
            }}
          >
            {activeCompany?.name}
          </Typography>
          <IconButton
            onClick={() =>
              location.pathname !== settingsPath && navigate(settingsPath)
            }
          >
            <TuneIcon
              sx={{
                color: 'text.primary',
                fontSize: {
                  xs: '1.8rem',
                  sm: '1.8rem',
                  md: '2rem',
                  lg: '2.2rem',
                  xl: '2.2rem',
                },
              }}
            />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
