import { StateCreator } from 'zustand'

interface SharedState {
  isLoading: boolean
}

export interface SharedSlice extends SharedState {
  setIsLoading: (newData: boolean) => void

  /**
   * Resets Shared Slice state
   */
  resetSharedSlice: () => void
}

const initialState: SharedState = {
  isLoading: false,
}

export const createSharedSlice: StateCreator<
  SharedSlice,
  [],
  [],
  SharedSlice
> = (set) => ({
  // State variables
  ...initialState,

  // State setters
  setIsLoading: (newData) => set({ isLoading: newData }),
  resetSharedSlice: () => set(initialState),
})
