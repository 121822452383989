import { PaletteMode, Theme } from '@mui/material'
import { createTheme } from '@mui/material/styles'

// Define any colors which could be used in multiple places in theme here
const textFontSize = 14

const primaryMainColor = { light: '#3B73FA', dark: '#3B73FA' }
const primaryTextColor = { light: 'rgba(0, 0, 0, 0.87)', dark: '#FFFFFF' }
const defaultBackgroundColor = { light: '#FFFFFF', dark: '#262626' }
const selectedSubmenuColor = { light: '#d5d5d5', dark: '#5A5858' }

export const getDesignTokens = (mode: PaletteMode) =>
  ({
    palette: {
      mode,
      primary: {
        main: primaryMainColor[mode],
      },
      background: {
        default: defaultBackgroundColor[mode],
        paper: defaultBackgroundColor[mode],
      },
      text: {
        primary: primaryTextColor[mode],
      },
      selectedSubmenu: {
        main: selectedSubmenuColor[mode],
      },
    },
    components: {
      MuiListItemButton: {
        styleOverrides: {
          root: {
            '&.Mui-selected': {
              backgroundColor: `${primaryMainColor[mode]} !important`,
            },
          },
        },
      },
    },
  }) as unknown as Theme

// Set up some customisation for MUI components that can be rendered by React
const getTheme = (mode: PaletteMode) =>
  createTheme({
    ...getDesignTokens(mode),
    typography: {
      fontSize: textFontSize,
      fontFamily: 'Avenir',
      h1: {
        fontSize: '3rem',
        fontWeight: 400,
        lineHeight: '116.7%',
      },
      h2: {
        fontSize: '2.4rem',
        fontWeight: 300,
        lineHeight: '120%',
        letterSpacing: '-0.05rem',
      },
      h3: {
        fontSize: '2.4rem',
        fontWeight: 400,
        lineHeight: '116.7%',
      },
      h4: {
        fontSize: '2rem',
        fontWeight: 400,
        lineHeight: '123.5%',
        letterSpacing: '0.025rem',
      },
      h5: {
        fontSize: '1.4rem',
        fontWeight: 700,
        lineHeight: '133.4%',
      },
      h6: {
        fontSize: '1.4rem',
        fontWeight: 400,
        lineHeight: '160%',
        letterSpacing: '0.015rem',
      },
      caption: {
        fontSize: '1.2rem',
        fontWeight: 400,
        lineHeight: '166%',
        letterSpacing: '0.04rem',
      },
      overline: {
        fontSize: '1.2rem',
        fontWeight: 400,
        lineHeight: '266%',
        letterSpacing: '0.1rem',
      },
      subtitle2: {
        fontSize: '1.2rem',
        fontWeight: 400,
        lineHeight: '157%',
        letterSpacing: '0.01rem',
      },
      subtitle1: {
        fontSize: '1.6rem',
        fontWeight: 400,
        lineHeight: '175%',
        letterSpacing: '0.015rem',
      },
      body2: {
        fontSize: '1.4rem',
        fontWeight: 400,
        lineHeight: '143%',
        letterSpacing: '0.017rem',
      },
      body1: {
        fontSize: '1.4rem',
        fontWeight: 400,
        lineHeight: '150%',
        letterSpacing: '0.015rem',
      },
    },
  })

export default getTheme
