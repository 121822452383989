import { ErrorBoundary } from '@/utils'
import React, { Suspense } from 'react'
import { useLoaderData, Await } from 'react-router-dom'
import FullPageLoader from './FullPageLoader'

const withPageLoading = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const ComponentWithPageLoading: React.FC<P> = (props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const loaderData: any = useLoaderData()

    return (
      <Suspense fallback={<FullPageLoader />}>
        <Await resolve={loaderData?.data} errorElement={<ErrorBoundary />}>
          <WrappedComponent {...props} />
        </Await>
      </Suspense>
    )
  }

  return ComponentWithPageLoading
}

export default withPageLoading
